import axios from "axios";

const subdomain = window.location.hostname.split(".")[0];
const baseURL = `http://${subdomain}.api.learnmetenant.com`;

const httpClientTwo = axios.create({
  // baseURL: import.meta.env.VITE_APP_API_BASE_URL
  // baseURL: "http://54.156.255.253:8080"
  baseURL
});

httpClientTwo.interceptors.request.use(
  (config) => {
    const user = JSON.parse(localStorage.getItem("persist:root"));
    const { currentUser } = JSON.parse(user.user);
    if (currentUser?.token && config.url !== "/api/v1/users/sign_in") {
      config.headers["Authorization"] = "Bearer " + currentUser.token;
    }

    config.headers["ngrok-skip-browser-warning"] = "true";
    return config;
  },
  (error) => {
    Promise.reject(error);
    console.log(error);
  }
);
export default httpClientTwo;
